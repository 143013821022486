/**
 * Created by Yzh
 * Datetime: 2021/6/4 10:02
 *
 */

export const menuList = [
    {
        name: "办公管理",
        index: "officeManagement",
        type: 1,
        submenuList: [
            {
                id: 2,
                name: "校园管理",
                index: "schoolManage",
                menuList: [
                    {
                        id: 202,
                        name: "通知公告",
                        index: "/system/officeManagement/schoolManage/notice",
                    },
                    {
                        id: 203,
                        name: "通知公告管理",
                        index: "/system/officeManagement/schoolManage/notificationManagement",
                    },
                    {
                        id: 204,
                        name: "报修",
                        index: "/system/officeManagement/schoolManage/reportForRepair",
                    },
                    {
                        id: 206,
                        name: "用车",
                        index: "/system/officeManagement/schoolManage/useCar",
                    },
                    {
                        id: 205,
                        name: "请假",
                        index: "/system/officeManagement/schoolManage/leave",
                    },
                    {
                        id: 207,
                        name: "临时假",
                        index: "/system/officeManagement/schoolManage/temporaryLeave",
                    },
                    {
                        id: 201,
                        name: "通讯录",
                        index: "/system/officeManagement/schoolManage/addressList",
                    },
                    // {不要了
                    //     id: "",
                    //     name: "问卷调查",
                    //     index: "/system/officeManagement/schoolManage/addressList",
                    // },
                ],
            },
            {
                id: 10,
                name: "公文管理",
                index: "documentManagement",
                menuList: [
                    {
                        id: 1001,
                        name: "发文管理",
                        index: "/system/officeManagement/documentManagement/sendingManagement",
                    },
                    {
                        id: 1002,
                        name: "收文管理",
                        index: "/system/officeManagement/documentManagement/receivingManagement",
                    },
                ],
            },
            {
                id: 19,
                name: "计划总结",
                index: "planSummary",
                menuList: [
                    {
                        id: 1901,
                        name: "计划总结",
                        index: "/system/officeManagement/planSummary/planSummary",
                    },
                ],
            },
        ],
    },
    {
        name: "党务建设",
        index: "partyAffairsConstruction",
        type: 0,
        submenuList: [
            {
                id: 1201,
                name: "信息发布",
                index: "/system/partyAffairsConstruction/InformationPublish",
            },
            {
                id: 1202,
                name: "活动管理",
                index: "/system/partyAffairsConstruction/activityManagement",
            },
        ],
    },
    {
        name: "教务教学管理",
        index: "educationalTeaching",
        type: 1,
        submenuList: [
            {
                id: 1,
                name: "教务管理",
                index: "educational",
                menuList: [
                    {
                        id: 109,
                        name: "学校信息",
                        index:
                            "/system/educationalTeaching/educational/schoolMessage",
                    },
                    {
                        id: 101,
                        name: "学期设置",
                        index: "/system/educationalTeaching/educational/termManage",
                    },
                    {
                        id: 102,
                        name: "年级/班级管理",
                        index:
                            "/system/educationalTeaching/educational/gradeClassManage",
                    },
                    {
                        id: 104,
                        name: "教师管理",
                        index:
                            "/system/educationalTeaching/educational/teacherManage",
                    },
                    {
                        id: 105,
                        name: "学生管理",
                        index:
                            "/system/educationalTeaching/educational/studentManage",
                    },
                    {
                        id: 106,
                        name: "学科设置",
                        index:
                            "/system/educationalTeaching/educational/subjectsManage",
                    },
                    {
                        id: 103,
                        name: "教室管理",
                        index: "/system/educationalTeaching/educational/classManage",
                    },
                    // {
                    //     id: "",
                    //     name: "课程管理",           不要了
                    //     index:
                    //         "/system/educationalTeaching/educational/courseAdjustmentManage",
                    // },
                    {
                        id: 107,
                        name: "部门管理",
                        index:
                            "/system/educationalTeaching/educational/departmentManage",
                    },
                    {
                        id: 108,
                        name: "课表管理",
                        index:
                            "/system/educationalTeaching/educational/timeTableManage",
                    },
                    {
                        id: 112,
                        name: "校历",
                        index:
                            "/system/educationalTeaching/educational/schoolCalendar",
                    },
                    {
                        id: 111,
                        name: "教学巡查",
                        index:
                            "/system/educationalTeaching/educational/teachingPatro",
                    },
                    {
                        id: 110,
                        name: "代课管理",
                        index:
                            "/system/educationalTeaching/educational/courseAdjustmentManage",
                    },
                ],
            },
            {
                id: 11,
                name: "自动排课管理",
                index: "automaticCourseArrangementManagement",
                menuList: [{
                    id: 1101,
                    name: "学科课时管理",
                    index: "/system/educationalTeaching/automaticCourseArrangementManagement/courseAndTimeManagement"
                }, {
                    id: 1102,
                    name: "职位课时管理",
                    index: "/system/educationalTeaching/automaticCourseArrangementManagement/positionCourseTimeManagement"
                }, {
                    id: 1103,
                    name: "课程总表",
                    index: "/system/educationalTeaching/automaticCourseArrangementManagement/courseTable"
                }]
            },
            {
                id: 7,
                name: "个别化教育资料",
                index: "individualization",
                menuList: [
                    {
                        id: 701,
                        name: "个别化教育资料",
                        index: "/system/educationalTeaching/Individualization/IndividualizationTable"
                    }
                ]
            },
            {
                id: 13,
                name: "教育科研中心",
                index: "educationScientificCenter",
                menuList: [
                    {
                        id: 1302,
                        name: "公开课管理",
                        index: "/system/educationalTeaching/educationScientificCenter/openClass"
                    },
                    {
                        id: 1303,
                        name: "评分系统",
                        index: "/system/educationalTeaching/educationScientificCenter/scoringSystem"
                    },
                    {
                        id: 1301,
                        name: "学科组计划总结",
                        index: "/system/educationalTeaching/educationScientificCenter/subjectPlanSummary"
                    },
                ]
            },
            {
                id: 9,
                name: "教学资源库",
                index: "teachingResourceLibrary",
                menuList: [
                    {
                        id: 901,
                        name: "教案管理",
                        index: "/system/educationalTeaching/teachingResourceLibrary/teachingManagement"
                    },
                    {
                        id: 902,
                        name: "课件管理",
                        index: "/system/educationalTeaching/teachingResourceLibrary/coursewareManagement"
                    },
                    {
                        id: 903,
                        name: "微课",
                        index: "/system/educationalTeaching/teachingResourceLibrary/microClass"
                    },
                    {
                        id: 904,
                        name: "教学论文",
                        index: "/system/educationalTeaching/teachingResourceLibrary/teachingPaper"
                    },
                    {
                        id: 905,
                        name: "其他",
                        index: "/system/educationalTeaching/teachingResourceLibrary/other"
                    },
                ]
            },
            {
                id: 8,
                name: "获奖管理",
                index: "awardManagement",
                menuList: [
                    {
                        id: 801,
                        name: "获奖教师",
                        index: "/system/educationalTeaching/awardManagement/awardWiningTeachers"
                    },
                    {
                        id: 802,
                        name: "获奖学生",
                        index: "/system/educationalTeaching/awardManagement/awardWiningStudents"
                    },
                    {
                        id: 803,
                        name: "学校获奖",
                        index: "/system/educationalTeaching/awardManagement/awardWiningSchools"
                    },
                    {
                        id: 804,
                        name: "班级获奖",
                        index: "/system/educationalTeaching/awardManagement/classAward"
                    },
                    {
                        id: 805,
                        name: "其他获奖",
                        index: "/system/educationalTeaching/awardManagement/otherAward"
                    },
                ],
            },
        ],
    },
    {
        name: "教师年度考核",
        index: "annualExamineManage",
        type: 0,
        submenuList: [
            {
                id: 301,
                name: "年度考核",
                index: "/system/annualExamineManage/annualExamine",
            },
            {
                id: 302,
                name: "数据汇总",
                index: "/system/annualExamineManage/dataSummary",
            },
            {
                id: 303,
                name: "测评",
                index: "/system/annualExamineManage/score",
            },
            {
                id: 304,
                name: "一票否决",
                index: "/system/annualExamineManage/oneTicketVeto",
            },
        ],
    },
    {
        name: "德育管理",
        index: "moralEducationManagement",
        type: 1,
        submenuList: [
            {
                id: 15,
                name: "常规得育管理",
                index: "routineFertilityManagement",
                menuList: [
                    {
                        id: 1501,
                        name: "活动管理",
                        index: "/system/moralEducationManagement/routineFertilityManagement/activityManagement",
                    },
                ]
            },
            {
                id: 14,
                name: "班级文化建设",
                index: "classCultureConstruction",
                menuList: [
                    {
                        id: 1401,
                        name: "登记",
                        index: "/system/moralEducationManagement/classCultureConstruction/register",
                    },
                    {
                        id: 1402,
                        name: "考核",
                        index: "/system/moralEducationManagement/classCultureConstruction/assessment",
                    },
                    //    暂时不要
                    // {
                    //     id: 1403,
                    //     name: "班级文化数据汇总",
                    //     index: "/system/moralEducationManagement/classCultureConstruction/classCultureDataSummary",
                    // }
                ]
            },
            {
                id: 18,
                name: "学校文化建设",
                index: "schoolCultureConstruction",
                menuList: [
                    {
                        id: 1801,
                        name: "学校文化建设",
                        index: "/system/moralEducationManagement/schoolCultureConstruction/schoolCultureConstruction",
                    },
                ]
            },
        ],
    },
    {
        name: "大队部管理",
        index: "largeForceManagement",
        type: 0,
        submenuList: [
            {
                id: 1601,
                name: "活动管理",
                index: "/system/largeForceManagement/activityManagement",
            },
        ],
    },
    {
        name: "家校互动",
        index: "homeSchoolInteraction",
        type: 0,
        submenuList: [
            {
                id: 601,
                name: "学生请假考核",
                index: "/system/homeSchoolInteraction/studentLeaveAudit",
            },
            {
                id: 603,
                name: "预约管理",
                index: "/system/homeSchoolInteraction/bookingManagement",
            },
            {
                id: 602,
                name: "发布家庭作业",
                index: "/system/homeSchoolInteraction/publishHomework",
            },

        ],
    },
    {
        name: "权限管理",
        index: "roleManage",
        type: 0,
        submenuList: [
            {
                id: 401,
                name: "角色权限管理",
                index: "/system/roleManage/roleManager",
            },
            {
                id: 402,
                name: "审批人管理",
                index: "/system/roleManage/examinerManagement"
            }
        ],
    },
    {
        name: "统一消息服务",
        index: "messageServer",
        type: 0,
        submenuList: [
            {
                id: 501,
                name: "统一消息管理",
                index: "/system/messageServer/messageManagement",
            },
            {
                id: 502,
                name: "意见反馈管理",
                index: "/system/messageServer/feedbackManagement",
            },

        ],
    },

    // {
    //     name: "系统管理",
    //     index: "systemManage",
    //     type: 0,
    //     submenuList: [
    //         {
    //             id: "",
    //             name: "行为跟踪与日志",
    //             index: "",
    //         },
    //     ],
    // },
]

export function roleMenuList(roleList) {
    let roleModuleIds = Array.from(new Set(roleList.map(role => role.moduleId)))
    let roleMenuIds = roleList.map(role => role.id)
    let roleMenuList = JSON.parse(JSON.stringify(menuList))
    for (let mainIndex = 0; mainIndex < roleMenuList.length; mainIndex++) {
        let main = roleMenuList[mainIndex]
        //   一层菜单，直接在submenuList里筛选
        if (main.type === 0) {
            //  筛选出二级菜单
            main.submenuList = main.submenuList.filter(submenu => roleMenuIds.indexOf(submenu.id) !== -1)
        }
        //   两层菜单，需要submenuList里的menuList筛选
        if (main.type === 1) {
            for (let subIndex = 0; subIndex < main.submenuList.length; subIndex++) {
                let submenu = main.submenuList[subIndex]
                //  如果二级菜单有内容，筛选三级菜单,否则删除整个二级菜单
                if (roleModuleIds.indexOf(submenu.id) !== -1) {
                    submenu.menuList = submenu.menuList.filter(menu => roleMenuIds.indexOf(menu.id) !== -1)
                } else {
                    main.submenuList.splice(subIndex, 1)
                    subIndex -= 1
                }
            }
        }
        //  如果整个二级菜单无内容，删除整个一级菜单
        if (main.submenuList.length === 0) {
            roleMenuList.splice(mainIndex, 1)
            mainIndex -= 1
        }
    }
    return roleMenuList
}
