<template>
  <div class="menu-frame">
    <el-container style="height: 100vh">
      <el-header>
        <div class="header-right">
          <img :src="$fileUploadDomain +$store.state.userInfo.logo"
               v-if="$store.state.userInfo.logo"
               @click="$router.push('/system')">
        </div>
        <div class="header-left">
          <span>{{ $store.state.userInfo.sname}}</span>
          <div class="flex-box">
            <el-badge :max="99"
                      class="icon-box"
                      :value="$store.state.unreadMessage">
              <span class="el-icon-bell "
                    @click="$router.push({name:'messageManagement'})"></span>
            </el-badge>
            <el-dropdown>
              <div class="user-avatar">
                <img v-if="userInfo.icon"
                     :onerror="defaultImg"
                     :src="$fileUploadDomain + $store.state.userInfo.icon">
                <img v-else
                     :src="'../assets/images/login_bg.jpg'">
                <div>
                  <p>{{$store.state.userInfo.username?$store.state.userInfo.username:userInfo.username}}</p>
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="personalCenterClick">个人中心</el-dropdown-item>
                <el-dropdown-item>系统设置</el-dropdown-item>
                <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- <div class="header">
          <div class="header-logo-school-name">
            <img :src="$fileUploadDomain +$store.state.userInfo.logo"
                 v-if="$store.state.userInfo.logo"
                 @click="$router.push('/system')">
            <span>{{ $store.state.userInfo.sname}}</span>
          </div>
          <div>
            <div class="flex-box">
              <el-badge :max="99"
                        class="icon-box"
                        :value="$store.state.unreadMessage">
                <span class="el-icon-bell "
                      @click="$router.push({name:'messageManagement'})"></span>
              </el-badge>
              <el-dropdown>
                <div class="user-avatar">
                  <img v-if="userInfo.icon"
                       :onerror="defaultImg"
                       :src="$fileUploadDomain + $store.state.userInfo.icon">
                  <img v-else
                       :src="'../assets/images/login_bg.jpg'">
                  <div>
                    <p>{{$store.state.userInfo.username?$store.state.userInfo.username:userInfo.username}}</p>
                  </div>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="personalCenterClick">个人中心</el-dropdown-item>
                  <el-dropdown-item>系统设置</el-dropdown-item>
                  <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div> -->
      </el-header>
      <el-container style="height: calc(100% - 70px);">
        <el-aside width="250px"
                  style="background-color: #00875a;">
          <el-menu router
                   unique-opened
                   background-color="#00875a"
                   text-color="#d7e9e3"
                   active-text-color="#ffffff"
                   class="el-menu-vertical-demo">
            <el-menu-item index="/system">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span slot="title">首页</span>
              </template>
            </el-menu-item>
            <el-submenu v-for="mainMenu in menuList"
                        :key="mainMenu.name"
                        :index="mainMenu.index">
              <template slot="title">
                <span slot="title">{{mainMenu.name}}</span>
              </template>
              <div v-if="mainMenu.type===1">
                <el-submenu v-for="submenu in mainMenu.submenuList"
                            :key="submenu.name"
                            :index="submenu.index">
                  <template slot="title">
                    <span slot="title">{{submenu.name}}</span>
                  </template>
                  <el-menu-item v-for="menu in submenu.menuList"
                                :key="menu.name"
                                :index="menu.index">{{menu.name}}
                  </el-menu-item>
                </el-submenu>
              </div>
              <el-menu-item v-else
                            v-for="submenu in mainMenu.submenuList"
                            :key="submenu.name"
                            :index="submenu.index">
                <span>{{submenu.name}}</span>
              </el-menu-item>
            </el-submenu>
            <!-- <el-menu-item index="/system/unifiedMessagingService">
              <template slot="title">
                <span slot="title">统一消息服务</span>
              </template>
            </el-menu-item> -->
          </el-menu>
        </el-aside>
        <el-main style="margin: 10px;padding: 0">
          <el-page-header style="padding: 20px"
                          @back="returnPage">
            <div class="page-header"
                 slot="content">{{$route.meta.name}}</div>
          </el-page-header>
          <router-view class="main-view" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { roleMenuList } from "@/utils/menuList";

export default {
  name: "menuFrame",
  data() {
    return {
      activeIndex: 1,
      menuList: [],
      path: `ws://13:82/socket`,
      ws: {},
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      defaultImg: 'this.src="' + require("../assets/images/zhux.jpg") + '"',
      iconUrl: "",
      logo: "",
    };
  },
  created() {
    this.iconUrl = this.$fileUploadDomain + this.$store.state.userInfo.icon;
    this.path = `${this.path}/${this.userInfo.userid}`;
    this.menuList = roleMenuList(
      JSON.parse(sessionStorage.getItem("userInfo")).nav
    );
    this.loadUnreadMessage();
    this.init();
    this.loadSchoolMessage();
  },

  methods: {
    //init函数可在页面加载的时候就进行初始化或者根据自己的业务需求在需要打开通讯的时候在进行初始化
    init() {
      // 实例化socket，这里的实例化直接赋值给this.ws是为了后面可以在其它的函数中也能调用websocket方法，例如：this.ws.close(); 完成通信后关闭WebSocket连接
      this.ws = new WebSocket(this.path);

      //监听是否连接成功
      this.ws.onopen = () => {
        console.log("ws连接状态：" + this.ws.readyState);
        //连接成功则发送一个数据
        this.ws.send("连接成功");
      };

      //接听服务器发回的信息并处理展示
      this.ws.onmessage = (data) => {
        console.log(data);
        if (data.data !== "连接成功") {
          //正则表达式 匹配全部"\" 需要加 /g
          let reg = /\\/g;
          //使用replace方法将全部匹配正则表达式的转义符替换为空
          let message = JSON.parse(data.data.replace(reg, ""));
          this.$notify({
            title: "标题名称",
            message: message.message,
            duration: 0,
          });
          this.loadUnreadMessage();
        }
      };

      //监听连接关闭事件
      this.ws.onclose = () => {
        //监听整个过程中websocket的状态
        console.log("ws连接状态：" + this.ws.readyState);
      };

      //监听并处理error事件
      this.ws.onerror = function (error) {
        console.log(error);
      };
    },
    logout() {
      this.$router.push("/");
      sessionStorage.clear();
    },
    personalCenterClick() {
      this.$router.push({ name: "personalCenter" });
    },
    returnPage() {
      this.$router.go(-1);
    },
    loadUnreadMessage() {
      const that = this;
      this.$post("/base/queryNotNews.do", {})
        .then((res) => {
          that.$store.commit("updateUnreadMessage", res.data.data.length);
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadSchoolMessage() {
      const that = this;
      this.$post("/media/querySchool.do", {})
        .then((res) => {
          that.logo = res.data.logo;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            $message: err.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
//.header {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 0 40px;

//   img {
//     height: 60px;
//     cursor: pointer;
//   }
//   .user-avatar {
//     display: flex;
//     align-items: center;
//     cursor: pointer;

//     img {
//       height: 40px;
//       border-radius: 50%;
//       border: 1px solid #eeeeee;
//       margin-right: 10px;
//     }

//     img:hover {
//       border: 1px solid #409eff;
//     }
//   }
//}
.icon-box {
  font-size: 26px;
  margin-right: 20px;
}
.icon-box:hover {
  cursor: pointer;
  opacity: 0.8;
}
.el-menu-vertical-demo {
  text-align: left;
}
.flex-box {
  display: flex;
  justify-items: center;
}

.el-menu-item.is-active {
  background-color: rgb(17, 105, 76) !important;
}

.main-view {
  height: calc(100% - 110px);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  overflow-y: auto;
}

.header-logo-school-name {
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.header-logo-school-name > img {
  height: 50px;
}
.header-logo-school-name > span {
  font-size: 28px;
  padding-left: 38px;
}
/deep/ .el-header {
  padding: 0;
  display: flex;
}
.header-right {
  background-color: rgb(0, 135, 90);
  width: 249px;
}
.header-right > img {
  padding: 5px 30px;
  height: 50px;
  border-radius: 50%;
}
.header-left {
  width: 100%;
  flex: 1;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  background-color: rgb(204, 229, 128);
  align-items: center;
}
.header-left > span {
  font-size: 28px;
  padding-left: 15px;
}
.user-avatar {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 15px;
  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #eeeeee;
    margin-right: 10px;
  }

  img:hover {
    border: 1px solid #409eff;
  }
}
</style>
